export interface Operation {
  id: string;
  done: boolean;
  status: OperationStatus;
  result?: ResultSuccess | ResultError;
}
export enum OperationStatus {
  Success = 'success',
  Failed = 'failed',
  Processing = 'processing',
  NotStarted = 'notStarted'
}

export type ResultError = {
  message: string;
};
export type ResultSuccess = {
  url: string;
};
