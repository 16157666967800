import {
  getCropUrls,
  getPreviewImageUrl
} from 'src/services/smart-digitization-file-uploader';
import {
  cropSvg,
  getSvgProperties
} from 'src/services/smart-digitization-svg-editor';
import {
  VectorizationFlowActionTypes,
  VectorizationFlowActions
} from './vectorizationFlowReducer';
import {
  UploadFileErrorsEnum,
  VectorizationFlowData
} from 'src/types/VectorizationFlowTypes';
import {
  cropRaster,
  editStatus
} from 'src/services/smart-digitization-image-converter';
import { OperationStatus } from 'src/types/operation';
import {
  MAX_PX_AREA,
  MINIMUM_PX_SIZE
} from '../SelectionLayer/SelectionLayer.utils';
import { defaultRetryPolicy } from 'src/utils/utils';

const getUrlImage = async (
  jobID: string,
  vectorizationFlowData: VectorizationFlowData,
  dispatchVectorizationFlowData: React.Dispatch<VectorizationFlowActions>,
  redirectToLogin: () => void,
  getAuthorizationToken: () => Promise<string>
) => {
  try {
    dispatchVectorizationFlowData({
      type: VectorizationFlowActionTypes.SET_IMAGE_PREVIEW_LOADING,
      payload: { jobID }
    });
    const token = await getAuthorizationToken();

    const imageUrl = await getPreviewImageUrl(
      vectorizationFlowData.userInputData.jobID,
      token,
      redirectToLogin
    );

    const response = await fetch(imageUrl);
    if (response.status !== 200) {
      throw new Error('Error fetching preview');
    }
    if (response.headers.get('Content-Type') === 'image/svg+xml') {
      // if the draw is an SVG, we need to get the width and height calling backend
      const svgInfo = await getSvgProperties(imageUrl, token, redirectToLogin);
      dispatchVectorizationFlowData({
        type: VectorizationFlowActionTypes.SET_IMAGE_DATA,
        payload: {
          type: 'vector',
          url: imageUrl,
          width: svgInfo.width,
          height: svgInfo.height,
          jobID: jobID
        }
      });
    } else {
      // if the draw is a raster, we can get the width and height from the image itself
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        if (img.width < MINIMUM_PX_SIZE || img.height < MINIMUM_PX_SIZE) {
          dispatchVectorizationFlowData({
            type: VectorizationFlowActionTypes.SET_CONVERT_RESULT,
            payload: {
              success: false,
              errors: [UploadFileErrorsEnum.PIXEL_TOO_SMALL]
            }
          });
          return;
        }

        dispatchVectorizationFlowData({
          type: VectorizationFlowActionTypes.SET_IMAGE_DATA,
          payload: {
            type: 'raster',
            url: imageUrl,
            width: img.width,
            height: img.height,
            jobID: jobID
          }
        });
      };
      img.onerror = () => {
        if (vectorizationFlowData.userInputData.jobTotalPixels > MAX_PX_AREA) {
          dispatchVectorizationFlowData({
            type: VectorizationFlowActionTypes.SET_CONVERT_RESULT,
            payload: {
              success: false,
              errors: [UploadFileErrorsEnum.PIXEL_TOO_LARGE]
            }
          });
          return;
        }

        dispatchVectorizationFlowData({
          type: VectorizationFlowActionTypes.SET_IMAGE_PREVIEW_ERROR,
          payload: { jobID }
        });
      };
    }
  } catch (error) {
    dispatchVectorizationFlowData({
      type: VectorizationFlowActionTypes.SET_IMAGE_PREVIEW_ERROR,
      payload: { jobID }
    });
  }
};
const processPreviewAndCrop = async (
  jobID: string,
  vectorizationFlowData: VectorizationFlowData,
  dispatchVectorizationFlowData: React.Dispatch<VectorizationFlowActions>,
  redirectToLogin: () => void,
  getAuthorizationToken: () => Promise<string>
) => {
  try {
    const token = await getAuthorizationToken();
    const cropSettings = vectorizationFlowData.userInputData.crop;
    // convert the crop settings to integers
    cropSettings.x = Math.round(cropSettings.x);
    cropSettings.y = Math.round(cropSettings.y);
    cropSettings.width = Math.round(cropSettings.width);
    cropSettings.height = Math.round(cropSettings.height);
    dispatchVectorizationFlowData({
      type: VectorizationFlowActionTypes.SET_IMAGE_CROP_LOADING,
      payload: { jobID }
    });
    // call file-uploader back end to get the URL to download the uncropped image and the URL to upload the cropped image
    const cropUrls = await getCropUrls(jobID, token, redirectToLogin);

    if (vectorizationFlowData.imageData?.type === 'vector') {
      // if the draw is a vector, call svg-editor
      await cropSvg(
        cropUrls.fileOrigin,
        cropUrls.fileDestination,
        token,
        cropSettings,
        redirectToLogin
      );
      dispatchVectorizationFlowData({
        type: VectorizationFlowActionTypes.SET_IMAGE_DATA,
        payload: {
          type: vectorizationFlowData.imageData?.type,
          url: cropUrls.fileOrigin,
          width: cropSettings.width,
          height: cropSettings.height,
          jobID: jobID
        }
      });
    } else {
      // if the draw is a raster, call image-converter
      let editOperation = await cropRaster(
        cropUrls.fileOrigin,
        cropUrls.fileDestination,
        token,
        cropSettings,
        redirectToLogin
      );
      let retry = 0;
      // wait to get the cropped image
      while (!editOperation.done && retry < defaultRetryPolicy.retries) {
        await new Promise((resolve) =>
          setTimeout(resolve, defaultRetryPolicy.delayMs)
        );
        retry++;
        editOperation = await editStatus(
          editOperation.id,
          token,
          redirectToLogin
        );
      }
      if (
        !editOperation.done ||
        editOperation.status !== OperationStatus.Success
      )
        throw new Error('Failed to crop raster');
      // On successful crop, update preview image data and UI state.
      const img = new Image();
      img.src = cropUrls.fileOrigin;
      img.onload = () => {
        dispatchVectorizationFlowData({
          type: VectorizationFlowActionTypes.SET_IMAGE_DATA,
          payload: {
            type: vectorizationFlowData.imageData?.type,
            url: cropUrls.fileOrigin,
            width: img.width,
            height: img.height,
            jobID: jobID
          }
        });
      };
      img.onerror = () => {
        dispatchVectorizationFlowData({
          type: VectorizationFlowActionTypes.SET_IMAGE_PREVIEW_ERROR,
          payload: { jobID }
        });
      };
    }
  } catch (error) {
    dispatchVectorizationFlowData({
      type: VectorizationFlowActionTypes.SET_IMAGE_CROP_ERROR,
      payload: { jobID }
    });
  }
};
export { getUrlImage, processPreviewAndCrop };
