import type { UserVectorizationData } from './UserInputFormTypes';

export interface VectorizationFlowData {
  step: VectorizationFlowStepsEnum;
  userInputData: UserVectorizationData;
  imageData: ImageData;
  convertResult?: ConvertResultData;
  loader: Loader;
}
export interface ConvertResultData {
  success: boolean;
  errors?: UploadFileErrorsEnum[];
}

export interface Loader {
  isLoading: boolean;
  messageTranslated?: string;
}

export enum VectorizationFlowStepsEnum {
  UploadFile = 0,
  UserInputForm = 1,
  UserInputSummary = 2,
  ConvertResult = 3
}

export type CropSettings = {
  x: number;
  y: number;
  width: number;
  height: number;
};

// FileState enum
export enum FileState {
  UPLOADED = 'uploaded',
  SECURITY_SCANNING = 'securityScanning',
  ANALYZING = 'analyzing',
  WAITING_FOR_VECTORIZE = 'waitingForVectorize',
  CONVERTING_PDF = 'convertingPDF',
  VECTORIZING = 'vectorizing',
  CONVERTING_SVG = 'convertingSVG',
  SUCCESS = 'success',
  FAILED = 'failed',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CANCELLED = 'cancelledByUser'
}

type ImageData = {
  type: 'raster' | 'vector';
  url: string;
  width: number;
  height: number;
  errorPreview: boolean;
  errorCrop: boolean;
  isPreviewLoading: boolean;
  isCropLoading: boolean;
};

// VectorizationStatus enum
export enum VectorizationStatus {
  UPLOADED = 'uploaded',
  SECURITY_SCANNING = 'securityScanning',
  ANALYZING = 'analyzing',
  WAITING_FOR_VECTORIZE = 'waitingForVectorize',
  CONVERTING_PDF = 'convertingPDF',
  VECTORIZING = 'vectorizing',
  CONVERTING_SVG = 'convertingSVG',
  SUCCESS = 'success',
  FAILED = 'failed',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CANCELLED = 'cancelledByUser'
}

// Errors that can occur during the upload process (upload file to S3, analyze file, antivirus scan)
export enum UploadFileErrorsEnum {
  SECURITY_SCAN = 'securityScan',
  FORMAT = 'format',
  COLOR_MODEL = 'colorModel',
  RESOLUTION = 'resolution',
  PIXEL_SIZE = 'pixelSize',
  PIXEL_TOO_SMALL = 'pixelTooSmall', // Image lower than minimum resolution
  PIXEL_TOO_LARGE = 'pixelTooLarge', // Image higher than maximum resolution and image can be resized (Image error on load)
  FILE_SIZE = 'fileSize',
  PAGE_SIZE = 'pageSize',
  SINGLE_PAGE = 'singlePage',
  ERROR = 'error',
  UNKNOWN = 'unknown'
}

// VectorizationError enum
export enum VectorizationError {
  SECURITY_SCAN = 'securityScan',
  PDF_CONVERTER = 'pdfConverter',
  FILE_ANALYZER = 'fileAnalyzer',
  VECTORIZE_ERROR = 'vectorizationError',
  VECTORIZE_REJECT = 'vectorizationReject',
  SVG_CONVERTER = 'svgConverter',
  ERROR = 'error'
}

export enum FileAnalizerError {
  FORMAT = 'format',
  COLOR_MODEL = 'colorModel',
  RESOLUTION = 'resolution',
  PIXEL_SIZE = 'pixelSize',
  FILE_SIZE = 'fileSize',
  PAGE_SIZE = 'pageSize',
  SINGLE_PAGE = 'singlePage'
}

// Job type
export type Job = {
  conversionDate: Date;
  fileName: string;
  size: number;
  jobID: string;
  state: VectorizationStatus;
  vectorizationError?: VectorizationError;
  uploadDate: Date;
  downloadDate?: string;
  fileAnalizerError?: FileAnalizerError[];
  /** Image DPI Resolution */
  jobResolution: number;
  jobTotalPixels: number;
};
