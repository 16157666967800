import { RetryPolicy } from 'src/components/UserInput/Summary';
import { Stack } from 'src/types/stratus';
import { ErrorResponse } from '../types/ErrorResponse';

export const defaultRetryPolicy: RetryPolicy = {
  retries: 99,
  delayMs: 1500
};

const EMAIL_NOT_VERIFIED_ERROR_MESSAGE = 'email not verified';
const TERMS_NOT_ACCEPTED_ERROR_MESSAGE = 'terms and conditions not accepted';
const AUTH_VALIDATION_ERROR_MESSAGE = 'error validating user token';
// Receives an http response and checks if the status code is 401 or 403.
// If it is 403, and the message is email not verified, the user is redirected to the profile page.
// If it is 403, and the message is terms and conditions not accepted, user should be redirected to the terms and conditions page, but this page is not yet implemented.
// If it is 401 or 403, and the message is not email not verified or terms and conditions not accepted, the user is redirected to the login page.
// If it is 500 due to error validating user token, the user is redirected to the login page.
// Otherwise, the user is not redirected.
// This function is shared between smart-digitization MFEs
export const handleUnauthorizedResponse = async (
  response: Response,
  redirectToLogin: () => void
): Promise<void> => {
  let redirectURL = '';
  try {
    if (response.status === 403 || response.status === 401) {
      const errorResponse: ErrorResponse = await response.json();
      if (errorResponse.error === EMAIL_NOT_VERIFIED_ERROR_MESSAGE)
        redirectURL = 'https://myaccount.stg.cd.id.hp.com/profile';
      else if (errorResponse.error === TERMS_NOT_ACCEPTED_ERROR_MESSAGE)
        // TODO replace empty URL ('') by an MFE
        redirectURL = '';
      else redirectURL = '/login';
    } else if (response.status === 500) {
      // catch errors unmarshalling json
      try {
        const errorResponse: ErrorResponse = await response.json();
        // If 500 error is due to auth validation error, redirect to login. Otherwise, do not redirect.
        if (errorResponse.error === AUTH_VALIDATION_ERROR_MESSAGE)
          redirectURL = '/login';
      } catch (error) {
        redirectURL = '';
      }
    }
  } catch (error) {
    redirectURL = '/login';
  }
  if (redirectURL === '/login') redirectToLogin();
  else if (redirectURL !== '') window.location.href = redirectURL;
};

// getHotjarID returns the Hotjar ID based on the stack
// @param stack - the stack of the app
// @returns the Hotjar ID
export const getHotjarID = (stack: Stack) => {
  if (stack === Stack.prod) return 3487423;
  else return 3382859;
};
// querySelector for the Jarvis shell avatar button
export const JSHELL_AVATAR_BUTTON_SELECTOR =
  'button[data-testid="jarvis-react-shell-common-layout__avatar-button"] :first-child';
