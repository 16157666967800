import { CropSettings, Job } from 'src/types/VectorizationFlowTypes';

export type UserInputFormData = {
  source?: SourceEnum;
  contentType?: ContentTypeEnum;
};

export enum ContentTypeEnum {
  FloorPlan = 'floorplan',
  StructuralDetails = 'structure',
  SectionElevation = 'section',
  Others = 'other'
}

export enum SourceEnum {
  Digital = 'digital',
  Scanned = 'scanned'
}

export type UserVectorizationData = {
  form: UserInputFormData;
  crop: CropSettings;
  jobID: Job['jobID'];
  fileName: Job['fileName'];
  jobResolution: Job['jobResolution'];
  jobTotalPixels: Job['jobTotalPixels'];
};
