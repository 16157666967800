import { Stack } from '@jarvis/web-stratus-client';
import { GoogleTagManager } from '../assets/static/googleTagManager';

const eventDataLayer = (stack: Stack, { event, clientID, ...args }) => {
  GoogleTagManager.init(stack, {
    event: event,
    date: new Date().toUTCString(),
    clientID,
    ...args
  });
};

export default eventDataLayer;
